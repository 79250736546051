<template>
    <section class="text-block text-block--right-text" v-if="currentModule != null">
        <b-container fluid>        
            <b-row>
                <b-col cols="12" md="6" 
                    :style="{'background': 'url(' + moduleBgr + ')' }" 
                    class="text-block__img-wrap"                                       
                >
                    <div class="text-block__img" >                        
                        <img 
                            :src="src_url + currentModule.module_image[0].image.path + currentModule.module_image[0].image.file_name" :alt="currentModule.name" 
                            class="img-fluid"
                            v-inview="'fade-from-left'" 
                        />
                    </div>                    
                </b-col>
                <b-col cols="12" md="6" class="text-block__content-wrap" >
                    <div class="text-block__content" v-inview="'fade-from-right'">
                        <div class="text" v-html="currentModule.description"></div>                        
                        <router-link v-if="currentModule.target_link" :to="{path: currentModule.target_link}" class="btn btn--green-border btn--with-arrow" @click.native="setGTM()"><span>további részletek</span></router-link>
                    </div>                            
                </b-col>
            </b-row>            
        </b-container>
    </section>
</template>

<script>
// Use for module text
// the module's data loading is in the view page - use getModuls mixin


export default {    
    props:['identifier', 'currentPageModulesTexts'],
    data(){
        return{
            currentModule: null,
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME,            
        }
    },
    computed:{
         moduleBgr(){
            if (this.currentModule.image_background){
                return this.src_url + this.currentModule.image_background.path + this.currentModule.image_background.file_name
            } else {
                return null
            }
            
        }
    },
    created(){
        let _this = this

        if (_this.currentPageModulesTexts.length > 0) {            
            _this.currentModule = _this.currentPageModulesTexts.find(item => item.identifier == _this.identifier)
        } else {
            _this.currentModule = null
        }
        
    },
    methods:{
        setGTM(){
            let _this = this

            this.GTtrackGA4({
                'event' : 'event',
                'category': _this.currentUrlName,
                'action': 'tovabbi_reszletek_kattintas',                
                'page_type': _this.currentUrlName,
                'button': 'tovabbi_reszletek',            
                'clicked_text': 'További részletek',
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>