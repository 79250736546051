<template>
  <div class="home page">    
    <section class="slider-wrap slider--very-big" v-if="bigSlider.length > 0" >
      <BigSlider :sliderItems="bigSlider" :fadeEffect="true" />
    </section>    
    <TextBlock 
        :identifier="'fooldal_termekeink'" 
        :currentPageModulesTexts="currentPageModulesTexts" 
        v-if="currentPageModulesTexts.length > 0"
      />      
      <ProdFamilySlider  :needApiCall="true" :prodFamiliesAll="[]" />
      <RecipeOffer         
        v-if="currentPageModulesTexts.length > 0 && recipeCategories.length > 0"
        :identifier="'fooldal_recept_neked'" 
        :currentPageModulesTexts="currentPageModulesTexts" 
        :categories="recipeCategories"
        :recipes="recipes"        
      />
      <HistoryLead :imageSlider="imageSlider" :text="historyLead.description"  v-if="historyLead"/>
    <!-- <Newsletter /> -->       
    <PageLoading  /> 
  </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import viewMI from '@/mixins/viewMI.js'
import BigSlider from '@/components/pages/home/BigSlider.vue'
// import Newsletter from '@/components/layout/Newsletter.vue'
import TextBlock from '@/components/layout/TextBlock.vue'
import ProdFamilySlider from '@/components/pages/productFamily/ProdFamilySlider.vue'
import RecipeOffer from '@/components/layout/RecipeOffer.vue'
import HistoryLead from '@/components/pages/history/HistoryLead.vue'
import PageLoading from '@/components/layout/PageLoading.vue'

export default {
  name: 'Home',
  mixins: [apiH,viewMI],
  components:{
    BigSlider,
    // Newsletter,
    TextBlock,
    ProdFamilySlider,
    RecipeOffer,
    HistoryLead,
    PageLoading   
  },
  data(){
    return{
      bigSlider: [],
      sliderNameBig: 'homepage-slider-big',      
      sliderNameHistory:'sopronirol-lead',
      modulesIdentifiers: ['fooldal_termekeink', 'fooldal_recept_neked', 'sopronirol_lead'] ,      
      recipeCategories:[],
      recipes:[],      
      imageSlider:[],      
      // loadCountNeed: 3,
      // currentPageLoadNum: 0      
    }
  },
  computed:{
    historyLead(){
        let _this = this
        let moduleText = null

        if (_this.currentPageModulesTexts.length > 0) {            
            moduleText = _this.currentPageModulesTexts.find(item => item.identifier == 'sopronirol_lead')
        } else {
            moduleText = null
        }

        return moduleText
    }  
  },   
  created(){
    var _this = this
    this.loadSliders()
    this.getModuls(_this.modulesIdentifiers)    
    // this.getRecipeCategoriesVMI()
    this.getRecipeCategories()
    this.getRecipes()
    // this.getRecipeCategoriesVMI(null, null)
  },
  mounted(){
    this.setMetaTags({
        title: null, 
        desc: null, 
        keywords: null, 
        ogType: 'website', 
        ogTitle: null,                         
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc:null
    })
  },
  methods:{
    loadSliders(){
        var _this = this;                       

        _this.get('slider',
            {
                params: {                          
                    relations:1,                                       
                    where: [
                      ['where','status', '1'],
                      ['where_in','name', [_this.sliderNameBig, _this.sliderNameHistory]],
                    ],
                    relations_params: {
                      slider_image: {
                        order_by:[
                          ['created_at','desc'],
                          ['sort_order','asc']                
                        ],
                        where: [['where','status', '1']]
                      }
                    }
                }
            },
            function(resp){                                        
                // _this.bigSlider = resp.data.slider[0].slider_image
                let sliders = resp.data.slider                    
                    
                _this.imageSlider = sliders.find(item => item.name === _this.sliderNameHistory).slider_image                    
                _this.bigSlider = sliders.find(item => item.name === _this.sliderNameBig).slider_image

                //increase the page load num 
                // _this.currentPageLoadNum += 1
                
            }, function(err){
                console.log(err);
                _this.bigSlider = []
                _this.imageSlider = []
            }
        )
    },   
    getRecipeCategories(){
      let _this = this

      _this.get('recipe_category',
            {
                params: {                          
                    relations:1,                                       
                    where: [
                        ['where','status', '1'],
                    ],                    
                    // select: ['name', 'image', 'product_id']
                }
            },
            function(resp){                                        
                //we need the category if it has recipes
                let allCategories = resp.data.recipe_category
                allCategories.forEach(element => {
                    if (element.recipe) {
                      _this.recipeCategories.push(element)
                    }
                });

                //increase the page load num 
                // _this.currentPageLoadNum += 1
                
            }, function(err){
                console.log(err);
                _this.recipeCategories = []
            }
        )

    },
    getRecipes(){
      let _this = this

      _this.get('recipe',
            {
                params: {                          
                    relations:1,                
                    limit:4,                       
                    where: [
                        ['where','status', '1'], 
                        ['where','image!=', '0'],                                                                       
                    ],
                    order_by:[
                      ['created_at','desc'],
                      ['sort_order','asc']                
                    ],
                }
            },
            function(resp){                                        
                _this.recipes = resp.data.recipe  
                
                //increase the page load num 
                // _this.currentPageLoadNum += 1
                
            }, function(err){
                console.log(err);
                _this.recipes = []
            }
        )

    }    
  }
}
</script>
