<template>
    <section class="history__lead">
            <section class="text-block text-block--left-text" >
                <b-container fluid class="p-0">        
                    <b-row no-gutters>                       
                        <b-col cols="12" md="6" class="text-block__content-wrap"  >                    
                            <div class="text-block__content" v-inview="'fade-from-left'">
                                <div class="text" v-html="text"></div>                             
                            </div>                            
                        </b-col>
                        <b-col cols="12" md="6" class="d-md-flex align-self-center history__lead-slider" v-inview="'fade-from-right'" >
                            <ImageSlider :sliderItems="imageSlider" :fadeEffect="true"/>
                        </b-col>
                    </b-row>            
                </b-container>
            </section>
        </section>
</template>

<script>
import ImageSlider from '@/components/layout/ImageSlider.vue'

export default {
    props:['imageSlider', 'text'],
    components:{
        ImageSlider
    }
}
</script>