<template>
    <div class="slider slider--image">
        <b-carousel 
            :interval="0"
            controls
            indicators      
            :fade="fadeEffect"
        >        
            <b-carousel-slide
                v-for="sitem in sliderItems" :key="sitem.id"
                                              
            >   
                <!-- <template #img>
                    <a :href="sitem.target_url" v-if="sitem.target_url" class="d-xl-none">
                        <img :src="src_url + sitem.image.path + sitem.image.file_name" class="slider-img img-fluid"  />                
                    </a>
                    <img v-else :src="src_url + sitem.image.path + sitem.image.file_name" class="slider-img img-fluid d-xl-none"  />                
                </template>                          -->
                <template #img>
                    <a :href="sitem.target_url" v-if="sitem.target_url">
                        <img src="@/assets/images/noimage/image-slider-placeholder.png" class="slider-img img-fluid"  />                
                    </a>
                    <img v-else src="@/assets/images/noimage/image-slider-placeholder.png" class="slider-img img-fluid "  />                
                </template>                                         
                <div class="slider__content " 
                    :style="{'background-image': 'url('+  src_url + sitem.image.path + sitem.image.file_name + ')'}"
                >
                </div>
            </b-carousel-slide>
        </b-carousel>
    </div>
</template>

<script>
export default {
    props:['sliderItems', 'fadeEffect'],    
}
</script>
